import React, { useRef } from "react"
import PropTypes from "prop-types"
import Flex from "./Flex"
import { graphql } from "gatsby"

const LayoutChanger = ({
  location,
  align,
  children,
  itemsMoveStackedOnColumn = true,
}) => {
  align = !align ? "start" : align
  location = !location ? "left" : location

  let flexDirection,
    justifyContent,
    alignItems,
    width,
    child1Mt,
    child2Mt,
    child2Ml,
    child2Mr

  const [child1, child2] = children
  alignItems = align && align !== "center" ? `flex-${align}` : "center"
  alignItems = { _: "center", lg: alignItems }
  if (child2) {
    if (location === "left" || location === "right") {
      justifyContent = { _: "flex-start", lg: "space-between" }
      width = { _: "100%", lg: "calc(50% - 32px)" }

      if (location === "left") {
        child2Mt = { _: 7, lg: 0 }
        child2Ml = { _: 0, lg: 7 }
        flexDirection = { _: "column", lg: "row" }
      } else {
        child1Mt = { _: 7, lg: 0 }
        child2Mr = { _: 0, lg: 7 }
        flexDirection = { _: "column-reverse", lg: "row-reverse" }
      }
    } else if (location === "top" || location === "bottom") {
      width = "100%"
      child2Ml = 0
      child2Mr = 0
      if (location === "bottom") {
        flexDirection = "column-reverse"
        child1Mt = 7
      } else {
        flexDirection = "column"
        child2Mt = 7
      }
    }
  } else {
    width = "100%"
  }

  const flexDirColumn = String(flexDirection).includes("column")
  const child1Ref = useRef(null)

  if (!children) return null

  return (
    <Flex
      justifyContent={!child2 ? alignItems : justifyContent}
      flexDirection={flexDirection}
      alignItems={alignItems}
    >
      {child1 && (
        <Flex
          className="child1"
          mt={child1Mt}
          justifyContent={flexDirColumn || !child2 ? alignItems : null}
          flex={1}
        >
          <div style={{width: "100%"}} ref={child1Ref}>{child1}</div>
        </Flex>
      )}
      {child2 && (
        <Flex
          className="child2"
          flex={1}
          justifyContent={
            !itemsMoveStackedOnColumn && flexDirColumn
              ? "center"
              : flexDirColumn
              ? alignItems
              : null
          }
          maxWidth={
            itemsMoveStackedOnColumn
              ? child1Ref?.current?.clientWidth || 44
              : flexDirColumn
              ? width
              : "100%"
          }
          width="100%"
          mt={child2Mt}
          mr={child2Mr}
          ml={child2Ml}
        >
          {child2}
        </Flex>
      )}
    </Flex>
  )
}

LayoutChanger.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  align: PropTypes.oneOf(["start", "center", "end"]),
}

export const query = graphql`
  fragment LayoutChanger on Strapi_ComponentAtomsLayoutChanger {
    id
    align
    location
  }
`

export default LayoutChanger
