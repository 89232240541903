import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"

import Flex from "../atoms/Flex"
import Box from "../atoms/Box"

import Logo from "../molecules/Logo"

const animateTrack = keyframes`
 0% { transform: translateX(0);}
 100% { transform: translateX(-100%); }
`

const CarouselTrack = styled.div`
  position: relative;
  display: flex;
  width: 200vw; /* need explicit width here */
  min-width: fit-content; /* gives logos adequate spacing on smaller screens */
  justify-content: space-between;

  transform-origin: left;
  &:first-of-type {
    animation: ${animateTrack} ${props => props.numItems * 3}s linear infinite;
  }
  &:nth-of-type(2) {
    animation: ${animateTrack} ${props => props.numItems * 3}s linear infinite;
  }
`

const MIN_ARRAY_LENGTH = 15

const CarouselTrackInstance = ({ logoItems, cardFront }) => {
  return (
    <CarouselTrack numItems={logoItems.length || 10}>
      {logoItems.map((logo, i) => {
        return (
          <Box
            width={{ _: 8, sm: 12 }}
            height={{ _: 6, sm: 9 }}
            mx={{ _: 3, sm: 5 }}
            key={i}
          >
            <Logo
              shouldShowReveal={false}
              cardFront={cardFront}
              height="100%"
              {...logo}
            />
          </Box>
        )
      })}
    </CarouselTrack>
  )
}

const LogoCarousel = ({ logoItems, cardFront }) => {
  const [lengthenedLogoItems, setLengthenedLogoItems] = useState([])

  useEffect(
    function lengthenLogoItemsArray() {
      let buildingArray = logoItems
      while (buildingArray.length < MIN_ARRAY_LENGTH) {
        buildingArray = buildingArray.concat(logoItems)
      }
      setLengthenedLogoItems(buildingArray)
    },
    [logoItems]
  )

  // Height needs to match height of Box around Logo Items
  return (
    <Box height={{ _: 6, sm: 9 }}>
      <Flex
        py="50px"
        my="-50px"
        position="absolute"
        left="0"
        width="100%"
        overflow="hidden"
        alignItems="center"
      >
        <CarouselTrackInstance
          cardFront={cardFront}
          logoItems={lengthenedLogoItems}
        />
        <CarouselTrackInstance
          cardFront={cardFront}
          logoItems={lengthenedLogoItems}
        />
      </Flex>
    </Box>
  )
}

export default LogoCarousel
