import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Flex from "../atoms/Flex"
import { Title, Paragraph } from "../atoms/Typography"

const Stat = ({ stat, name, description, align }) => {
  const center = align === "center"
  return (
    <Flex
      maxWidth={25}
      mx={center ? "auto" : 0}
      height="100%"
      flexDirection="column"
      alignItems={center ? "center" : null}
    >
      <Paragraph fontSize={6} color="primary">
        {stat}
      </Paragraph>
      <Title variant="h3" mb={4}>
        {name}
      </Title>
      {description && (
        <Paragraph textAlign={center ? "center" : null}>
          {description}
        </Paragraph>
      )}
    </Flex>
  )
}

export default Stat

Stat.strapiProps = {
  stat: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
}

Stat.propTypes = {
  ...Stat.strapiProps,
  align: PropTypes.oneOf(["left", "center"]).isRequired,
}

export const query = graphql`
  fragment Stat on Strapi_ComponentMoleculesStat {
    stat
    name
    description
  }
`
