import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import LayoutChanger from "../atoms/LayoutChanger"
import Html from "../atoms/CustomHtml"
import Section from "../atoms/Section"

import SectionTitle from "../molecules/SectionTitle"
import Box from "../atoms/Box"

const CustomHtml = ({ section, sectionTitle, integration, layoutChanger }) => {
  return (
    <Section {...section}>
      <LayoutChanger {...layoutChanger}>
        <Box>
          {sectionTitle && <SectionTitle {...sectionTitle} maxWidth={44} />}
        </Box>
        <Box width="100%">
          {integration?.type === "customHtml" && integration?.data?.html && (
            <Html mb={-5} width="100%" html={integration.data.html} />
          )}
        </Box>
      </LayoutChanger>
    </Section>
  )
}

CustomHtml.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps),
  integration: PropTypes.shape({
    type: PropTypes.oneOf(["customHtml"]),
    data: PropTypes.shape({ html: PropTypes.string }),
  }),
}

export default CustomHtml

export const query = graphql`
  fragment CustomHtml on Strapi_ComponentSectionsCustomHtml {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    integration {
      type
      data
    }
    layoutChanger {
      ...LayoutChanger
    }
  }
`
