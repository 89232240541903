import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import Image from "../utils/Image"
import Reveal from "../utils/Reveal"

import Card from "../atoms/Card"
import Section from "../atoms/Section"
import Flex from "../atoms/Flex"
import Box from "../atoms/Box"

import InteractiveInfoCard from "../molecules/InteractiveInfoCard"
import SectionTitle from "../molecules/SectionTitle"

const StyledBox = styled(Box)`
  transition: opacity 0.3s cubic-bezier(0.17, 0.67, 0.58, 0.84);
`

const InteractiveInfoCards = ({
  section,
  sectionTitle,
  interactiveInfoCards,
  card,
  cardsAlign = "left",
  iconLocation = "top",
  imageLocation,
  interactiveInfoCardsImageLocation,
  iconSize,
  changeOnHover,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const imageAlignment = imageLocation || interactiveInfoCardsImageLocation

  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      <Flex
        width="100%"
        justifyContent="space-between"
        flexDirection={{
          _: "column",
          lg: imageAlignment === "right" ? "row-reverse" : "row",
        }}
      >
        <Box width={{ _: "100%", lg: "50%" }} mb={{ _: 6, lg: 0 }}>
          {interactiveInfoCards?.map((card, idx) => {
            return (
              <StyledBox
                width={"100%"}
                opacity={idx === activeIndex ? 1 : 0}
                height={idx === activeIndex ? "100%" : "0px"}
              >
                <Image {...card.image} />
              </StyledBox>
            )
          })}
        </Box>
        <Box width={{ _: "100%", lg: "45%" }}>
          <Flex
            width={"100%"}
            flexDirection={"column"}
            alignItems={{ _: "center" }}
          >
            {interactiveInfoCards.map((interactiveInfoCard, i) => (
              <InteractiveInfoCard
                mx={cardsAlign === "center" ? "auto" : 0}
                mb={5}
                width={"100%"}
                iconLocation={iconLocation}
                iconSize={iconSize}
                align={cardsAlign}
                {...interactiveInfoCard}
                key={i}
                idx={i}
                active={activeIndex === i}
                onClick={!changeOnHover ? () => setActiveIndex(i) : undefined}
                onMouseEnter={changeOnHover ? () => setActiveIndex(i) : undefined}
                card={card}
              />
            ))}
          </Flex>
        </Box>
      </Flex>
    </Section>
  )
}

export default InteractiveInfoCards

InteractiveInfoCards.propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  interactiveInfoCards: PropTypes.arrayOf(
    PropTypes.shape(InteractiveInfoCard.strapiProps)
  ),
  cardsAlign: PropTypes.oneOf(["left", "center"]).isRequired,
  iconLocation: PropTypes.oneOf(["top", "left"]).isRequired,
  card: PropTypes.shape(Card.strapiProps).isRequired,
  image: PropTypes.object,
  imageLocation: PropTypes.oneOf(["left", "right"]),
}

export const query = graphql`
  fragment InteractiveInfoCards on Strapi_ComponentSectionsInteractiveInfoCards {
    id
    changeOnHover
    interactiveInfoCardsImageLocation: imageLocation
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    interactiveInfoCards {
      ...InteractiveInfoCard
    }
    card {
      ...Card
    } 
  }
`
