import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import { Title, Label } from "../atoms/Typography"
import Box from "../atoms/Box"
import RichText from "../atoms/RichText"

const SectionTitle = ({
  title,
  titleColor,
  variant = "h2",
  as,
  label,
  descriptionRichText,
  align = "left",
  ...rest
}) => {
  return (
    <Box mx={align === "center" ? "auto" : ""} {...rest}>
      {label && (
        <Label mb={3} textAlign={align}>
          {label}
        </Label>
      )}
      <Title variant={variant} as={as} textAlign={align} color={titleColor}>
        {title}
      </Title>
      <RichText mt={3} textAlign={align} {...descriptionRichText} />
    </Box>
  )
}

SectionTitle.strapiProps = {
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.oneOf(["text", "primary"]),
  variant: PropTypes.oneOf(["h1", "h2"]).isRequired,
  as: PropTypes.oneOf(["h1", "h2"]),
  label: PropTypes.string,
  descriptionRichText: PropTypes.object,
  align: PropTypes.oneOf(["left", "center"]).isRequired,
}

SectionTitle.propTypes = {
  ...SectionTitle.strapiProps,
  ...Box.propTypes,
}

export default SectionTitle

export const query = graphql`
  fragment SectionTitle on Strapi_ComponentMoleculesSectionTitle {
    title
    titleColor
    variant
    as
    label
    align
    descriptionRichText {
      ...RichText
    }
  }
`
