import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { format } from "date-fns"

import Link from "../utils/Link"
import Image from "../utils/Image"

import Card from "../atoms/Card"
import Box from "../atoms/Box"
import ProportionalBox from "../atoms/ProportionalBox"
import { Title, Paragraph, Label } from "../atoms/Typography"

const Post = ({ title, description, thumbnail, date, link, showDate }) => {
  return (
    <Link {...link}>
      <Card height="100%">
        {thumbnail && (
          <ProportionalBox>
            <Image
              {...thumbnail}
              style={{ height: "100%" }}
              // imgStyle={{ objectFit: "cover" }}
            />
          </ProportionalBox>
        )}
        <Box bg="background.0" px={{ _: 4, sm: 5 }} py={4}>
          <Title variant="h3" mb={3}>
            {title}
          </Title>
          {description && <Paragraph>{description}</Paragraph>}
          {showDate && date && (
            <Label mt={4} opacity={0.5}>
              {format(new Date(date), "MMM d, y")}
            </Label>
          )}
        </Box>
      </Card>
    </Link>
  )
}

export default Post

Post.strapiProps = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  thumbnail: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  link: PropTypes.shape(Link.strapiProps),
}

Post.propTypes = {
  ...Post.strapiProps,
}

export const query = graphql`
  fragment Post on Strapi_ComponentMoleculesPosts {
    title
    description
    thumbnail {
      url
      alternativeText
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    date
    link {
      ...Link
    }
  }
`
