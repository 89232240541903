import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Card from "../atoms/Card"
import FlexGrid from "../atoms/FlexGrid"
import Section from "../atoms/Section"

import Logo from "../molecules/Logo"
import LogoCarousel from "../molecules/LogoCarousel"
import SectionTitle from "../molecules/SectionTitle"

const LogoGrid = ({
  section,
  sectionTitle,
  flexGrid,
  logoItems,
  displayMode = "grid",
  cardRevealAlign,
  useCardFront,
  useCardBack,
  cardFront,
  cardBack,
  zoomOnHover,
}) => {
  cardFront = { ...cardFront, useCard: useCardFront }
  cardBack = {
    ...cardFront,
    useCard: useCardBack,
    theme: cardBack?.theme,
    bg: cardBack?.bg,
  }

  const isScroll = displayMode === "scroll"
  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={7} maxWidth={42} />
        </Reveal>
      )}
      {isScroll && <LogoCarousel cardFront={cardFront} logoItems={logoItems} />}
      {!isScroll && (
        <FlexGrid {...flexGrid}>
          {logoItems.map((logo, i) => (
            <Logo
              zoomOnHover={zoomOnHover}
              cardFront={cardFront}
              cardBack={cardBack}
              align={cardRevealAlign}
              {...logo}
              key={i}
            />
          ))}
        </FlexGrid>
      )}
    </Section>
  )
}

export default LogoGrid

LogoGrid.propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  flexGrid: PropTypes.shape(FlexGrid.strapiProps).isRequired,
  logoItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayMode: PropTypes.oneOf(["grid", "scroll"]),
  useCardFront: PropTypes.oneOf(["card", "noCard"]),
  cardFront: PropTypes.shape(Card.strapiProps),
  useCardBack: PropTypes.bool,
  cardBack: PropTypes.shape(Card.strapiProps),
  cardRevealAlign: PropTypes.oneOf(["left", "center"]),
}

export const query = graphql`
  fragment LogoGrid on Strapi_ComponentSectionsLogoGrid {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    flexGrid {
      ...FlexGrid
    }
    logoItems {
      ...Logo
    }
    cardFront {
      ...Card
    }
    cardBack {
      ...Card
    }
    useCardFront
    useCardBack
    cardRevealAlign
    displayMode
    zoomOnHover
  }
`
