import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import HtmlReactParser from "html-react-parser"
import Box from "../atoms/Box"

const createScriptElement = (domNode) => {
  // console.dir(domNode, { depth: null })
  let script = document.createElement("script")

  // add all attributes
  Object.keys(domNode.attribs).forEach((attrib) => {
    script[attrib] = domNode.attribs[attrib]
  })

  domNode.children.forEach((child) => {
    const inlineScript = document.createTextNode(child.data)
    script.appendChild(inlineScript)
  })
  return script
}

// TODO: Order of html is not preserved - scripts will always be added at the end
// TODO: If html changes in Morphic app the scripts don't change until refresh
const CustomHtml = ({ html, ...rest }) => {
  // ref to add scripts to
  const ref = useRef()
  // isActive checks if we've already added scripts
  const [isActive, setIsActive] = useState(false)

  // This effect runs any scripts in html string
  useEffect(() => {
    if (html && ref.current && !isActive) {
      let scripts = []
      // Create all script elements
      HtmlReactParser(html, {
        replace: function (domNode) {
          if (domNode.type === "script") {
            let script = createScriptElement(domNode)
            scripts.push(script)
          }
        },
      })

      if (scripts.length > 0) {
        // Make sure scripts run in order by appending the next script
        // on the previous script's "onload" function
        scripts.forEach((script, i) => {
          if (i + 1 <= scripts.length - 1) {
            script.onload = function () {
              ref.current.appendChild(scripts[i + 1])
            }
          }
        })

        // Run the first script
        ref.current.appendChild(scripts[0])
        setIsActive(true)
      }
    }
  }, [html, isActive, setIsActive])

  if (!html) return null

  return (
    <Box ref={ref} {...rest}>
      {HtmlReactParser(html, {
        replace: function (domNode) {
          // Skip rendering of script tag on client
          if (domNode.type === "script" && typeof window !== "undefined") {
            return <span />
          }
        },
      })}
    </Box>
  )
}

CustomHtml.propTypes = {
  html: PropTypes.string,
  ...Box.propTypes,
}

export default CustomHtml
