import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Image from "../utils/Image"
import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import Flex from "../atoms/Flex"
import Button from "../atoms/Button"
import LayoutChanger from "../atoms/LayoutChanger"

import SectionTitle from "../molecules/SectionTitle"
import Box from "../atoms/Box"

const ImageText = ({ section, sectionTitle, button, image, layoutChanger }) => {
  return (
    <Section {...section}>
      <LayoutChanger itemsMoveStackedOnColumn={false} {...layoutChanger}>
        <Reveal>
          <Box>
            {sectionTitle && <SectionTitle maxWidth={44} {...sectionTitle} />}
            {button && (
              <Flex
                justifyContent={
                  sectionTitle.align === "center" ? "center" : "flex-start"
                }
                mt={5}
              >
                <Button {...button} />
              </Flex>
            )}
          </Box>
        </Reveal>
        {image && (
          <Box width="100%" maxWidth={44}>
            <Reveal>
              <Image style={{ width: "100%", height: "100%" }} {...image} />
            </Reveal>
          </Box>
        )}
      </LayoutChanger>
    </Section>
  )
}

export default ImageText

ImageText.propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  button: PropTypes.shape(Button.strapiProps),
  image: PropTypes.object,
  ...LayoutChanger.strapiProps,
}

export const query = graphql`
  fragment ImageText on Strapi_ComponentSectionsImageText {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    button {
      ...Button
    }
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    layoutChanger {
      ...LayoutChanger
    }
  }
`
