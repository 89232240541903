import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Box from "../atoms/Box"
import { Paragraph, Label, Title } from "../atoms/Typography"

const Quote = ({ quoteText, name, position, textAlign, ...rest }) => {
  return (
    <Box mx={textAlign === "center" ? "auto" : 0} mt={6} {...rest}>
      {quoteText && (
        <Title align={textAlign} variant="h2" as="p" mb={6}>
          {quoteText}
        </Title>
      )}
      {name && (
        <Label align={textAlign} mb={3}>
          {name}
        </Label>
      )}
      {position && <Paragraph align={textAlign}>{position}</Paragraph>}
    </Box>
  )
}

export default Quote

Quote.strapiProps = {
  quoteText: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  image: PropTypes.object,
}

export const query = graphql`
  fragment Quote on Strapi_ComponentMoleculesQuote {
    name
    position
    quoteText
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`
